import React from "react";

export const Header = (props) => {

  const openWhatsApp = () => {
    window.open("https://api.whatsapp.com/send?phone=+5511991934542&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20paisagismo%20da%20HF%20Paisagismo.", "_blank");
  }

  const styleProps = {
    fontWeight : "normal"
  }

  return (
    <header id="header">
      <div className="intro">
        <div className="overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-9 col-md-offset-2 intro-text">
                <h1>
                  {props.data ? props.data.title : "Loading"}
                  <span></span>
                </h1>
                <p className="sub-title fw-bold" style={styleProps}
                 
                
                >{props.data ? props.data.paragraph : "Loading"}</p>
                <button
                  onClick={openWhatsApp}
                  className="btn btn-custom btn-lg page-scroll"
                >
                  Entre em contato
                </button>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
