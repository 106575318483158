import React, {useEffect, useState} from "react";
import {Navigation} from "./components/navigation/navigation";
import {Header} from "./components/header";
import {About} from "./components/about/about";
import {Services} from "./components/services";
import {Gallery} from "./components/gallery/gallery";
import {Contact} from "./components/contact";
import JsonData from "./data/data.json";
import SmoothScroll from "smooth-scroll";
import {Team} from "./components/Team";
import "./App.css";

export const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
});

const App = () => {
    const [landingPageData, setLandingPageData] = useState({});
    useEffect(() => {
        setLandingPageData(JsonData);
    }, []);

    const openWhatsApp = () => {
        window.open("https://api.whatsapp.com/send?phone=+5511991934542&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20paisagismo%20da%20HF%20Paisagismo.", "_blank");
    }

    return (
        <div>
            <button className="fa fa-whatsapp"
                style={{
                    // size of the button
                    width: '120px',
                    height: '120px',
                    // icon size
                    fontSize: '50px',
                    position: 'fixed',
                    bottom: '20px',
                    right: '20px',
                    padding: '20px 20px',
                    backgroundColor: '#25D366',
                    color: '#fff',
                    border: 'none',
                    borderRadius: '50%',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                    cursor: 'pointer',
                    zIndex: 1000,
                }}
                onClick={() => openWhatsApp()}
            >
                
            </button>
            <Navigation/>
            <Header data={landingPageData.Header}/>
            <About data={landingPageData.About}/>
            {/*<Features data={landingPageData.Features} />*/}
            <Services data={landingPageData.Services}/>
            <Gallery data={landingPageData.Gallery}/>
            {/*<Testimonials data={landingPageData.Testimonials} />*/}
            {/* <Team data={landingPageData.Team} /> */}
            <Contact data={landingPageData.Contact}/>
        </div>
    );
};

export default App;
