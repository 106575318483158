import React, {useState} from "react";
import emailjs from "emailjs-com";

const initialState = {
    name: "",
    email: "",
    message: "",
};
export const Contact = (props) => {
    const [{name, email, message}, setState] = useState(initialState);

    const handleChange = (e) => {
        const {name, value} = e.target;
        setState((prevState) => ({...prevState, [name]: value}));
    };
    const clearState = () => setState({...initialState});

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(name, email, message);
        emailjs
            .sendForm("service_9iat53b_HF", "template_63olqd4", e.target, "d-plGeJO4AV4aLSoU")
            .then(
                (result) => {
                    console.log(result.text);
                    clearState();
                },
                (error) => {
                    console.log(error.text);
                }
            );
    };


    const removeSpacesAndHyphensAndParentheses = (phone) => {
        return phone.replace(/ /g, "").replace(/-/g, "").replace(/\(/g, "").replace(/\)/g, "");
    }


    const whatsappLink = (phone) => {
        return `https://api.whatsapp.com/send?phone=${removeSpacesAndHyphensAndParentheses(phone)}&text=Ol%C3%A1%2C%20gostaria%20de%20saber%20mais%20sobre%20os%20servi%C3%A7os%20de%20paisagismo%20da%20HF%20Paisagismo."`;
    }

    return (
        <div>
            <div id="contact">
                <div className="container">
                    <div className="col-md-8">
                        <div className="row">
                            <div className="section-title">
                                <h2>Entre em contato</h2>
                                <p>
                                    Preencha o formulário abaixo para entrar em contato
                                </p>
                            </div>
                            <form name="sentMessage" validate onSubmit={handleSubmit} className="form-container">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                placeholder="Nome"
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                placeholder="Email"
                                                required
                                                onChange={handleChange}
                                            />
                                            <p className="help-block text-danger"></p>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <textarea
                                        name="message"
                                        id="message"
                                        className="form-control"
                                        rows="4"
                                        placeholder="Mensagem"
                                        required
                                        onChange={handleChange}
                                    ></textarea>
                                    <p className="help-block text-danger"></p>
                                </div>
                                <div id="success"></div>
                                <button type="submit" className="btn btn-custom btn-lg">
                                    Enviar Mensagem
                                </button>
                            </form>
                        </div>
                    </div>
                    <div className="col-md-3 col-md-offset-1 contact-info">
                        <div className="contact-item">
                            <h3>Informações</h3>
                            <p>
                                <span>
                                    <i className="fa fa-map-marker"></i> Endereço
                                </span>
                                {props.data ? props.data.address : "loading"}
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-phone"></i> Telefones
                                </span>{" "}
                                <a href={whatsappLink(props.data ? props.data.phone : "")} target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-whatsapp"></i>
                                    {props.data ? props.data.phone : "loading"}
                                </a>
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                </span>{" "}
                                <a href={whatsappLink(props.data ? props.data.phone2 : "")} target="_blank" rel="noopener noreferrer">
                                    <i className="fa fa-whatsapp"></i>
                                    {props.data ? props.data.phone2 : "loading"}
                                </a>
                            </p>
                        </div>
                        <div className="contact-item">
                            <p>
                                <span>
                                    <i className="fa fa-envelope-o"></i> Email
                                </span>{" "}
                                < a href={`mailto:${props.data ? props.data.email : "loading"}`}>
                                {props.data ? props.data.email : "loading"}
                                </a>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <div className="row">
                            <div className="social">
                                <ul>
                                    <li>
                                        <a href={props.data ? props.data.instagram : "/"}>
                                            <i className="fa fa-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.facebook : "/"}>
                                            <i className="fa fa-facebook"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href={props.data ? props.data.whatsapp : "/"}>
                                            <i className="fa fa-whatsapp"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="footer">
                <div className="container text-center">
                    <p>
                        &copy; 2024 HF PAISAGISMO {" "}
                    </p>
                </div>
            </div>
            <style jsx>{`
                a {
                    color: inherit;
                    text-decoration: none;
                }
            `}</style>
        </div>
    );
};
